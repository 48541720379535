var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Custom Labeled Progress" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeCustomLabel) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v(
            "Need more control over the label? Provide your own label by using the default slot within a"
          )
        ]),
        _c("code", [_vm._v("<b-progress-bar>")]),
        _c("span", [_vm._v("sub-component, or by using the")]),
        _c("code", [_vm._v("label")]),
        _c("span", [_vm._v("or")]),
        _c("code", [_vm._v("label-html")]),
        _c("span", [_vm._v(" property on ")]),
        _c("code", [_vm._v("<b-progress-bar>")]),
        _vm._v(": ")
      ]),
      _c("div", { staticClass: "demo-vertical-spacing" }, [
        _c(
          "div",
          [
            _c("label", [_vm._v("Custom label via default slot")]),
            _c(
              "b-progress",
              { attrs: { max: _vm.max } },
              [
                _c("b-progress-bar", { attrs: { value: _vm.value } }, [
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm.value.toFixed(2)) + " / " + _vm._s(_vm.max)
                    )
                  ])
                ])
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c("label", [_vm._v("Custom label via property")]),
            _c(
              "b-progress",
              { staticClass: "progress-bar-info", attrs: { max: _vm.max } },
              [
                _c("b-progress-bar", {
                  attrs: {
                    value: _vm.value,
                    label: ((_vm.value / _vm.max) * 100).toFixed(2) + "%",
                    variant: "info"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c("label", [_vm._v("Custom label via property (HTML support)")]),
            _c(
              "b-progress",
              { staticClass: "progress-bar-warning", attrs: { max: _vm.max } },
              [
                _c("b-progress-bar", {
                  attrs: {
                    value: _vm.value,
                    "label-html": "<del>" + _vm.value + "</del>",
                    variant: "warning"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }