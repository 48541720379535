var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Animated Progress" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeAnimated) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [
          _vm._v("The striped gradient can also be animated by setting the")
        ]),
        _c("code", [_vm._v("animated")]),
        _c("span", [_vm._v("prop.")])
      ]),
      _vm._l(_vm.bars, function(bar) {
        return _c(
          "b-row",
          { key: bar.variant },
          [
            _c("b-col", { staticClass: "pt-2", attrs: { md: "2" } }, [
              _vm._v(" " + _vm._s(bar.variant) + ": ")
            ]),
            _c(
              "b-col",
              { staticClass: "pt-md-2", attrs: { md: "10" } },
              [
                _c("b-progress", {
                  key: bar.variant,
                  class: "progress-bar-" + bar.variant,
                  attrs: {
                    animated: "",
                    value: bar.value,
                    variant: bar.variant
                  }
                })
              ],
              1
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }