var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c("progress-basic"),
          _c("progress-colored"),
          _c("progress-label"),
          _c("progress-label-custom"),
          _c("progress-stripped"),
          _c("progress-multiple-bar"),
          _c("progress-animated")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }