var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Multiple bars" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeMultipleBar) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Include multiple")]),
        _c("code", [_vm._v("<b-progress-bar>")]),
        _c("span", [_vm._v("sub-components in a")]),
        _c("code", [_vm._v("<b-progress>")]),
        _c("span", [
          _vm._v(
            "component to build a horizontally stacked set of progress bars."
          )
        ])
      ]),
      _c(
        "b-progress",
        { attrs: { max: _vm.max } },
        [
          _c("b-progress-bar", {
            attrs: {
              variant: "danger",
              value: _vm.values[0],
              "show-progress": ""
            }
          }),
          _c("b-progress-bar", {
            attrs: {
              variant: "warning",
              value: _vm.values[1],
              "show-progress": ""
            }
          }),
          _c("b-progress-bar", {
            attrs: {
              variant: "primary",
              value: _vm.values[2],
              "show-progress": ""
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }