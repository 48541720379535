var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Labeled Progress" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeLabel) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v("Add labels to your progress bars by either enabling")
        ]),
        _c("code", [_vm._v("show-progress")]),
        _c("span", [_vm._v("(percentage of max) or")]),
        _c("code", [_vm._v("show-value")]),
        _c("span", [
          _vm._v(
            " for the current absolute value. You may also set the precision (number of digits after the decimal) via the "
          )
        ]),
        _c("code", [_vm._v("precision")]),
        _c("span", [_vm._v("prop (default is 0digits after the decimal).")])
      ]),
      _c("div", { staticClass: "demo-vertical-spacing" }, [
        _c(
          "div",
          [
            _c("label", [_vm._v("No label")]),
            _c("b-progress", { attrs: { value: "25", max: "100" } })
          ],
          1
        ),
        _c(
          "div",
          [
            _c("label", [_vm._v("Value label")]),
            _c("b-progress", {
              staticClass: "progress-bar-secondary",
              attrs: {
                value: "35",
                max: "100",
                "show-value": "",
                variant: "secondary"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          [
            _c("label", [_vm._v("Progress label")]),
            _c("b-progress", {
              staticClass: "progress-bar-success",
              attrs: {
                value: "45",
                max: "100",
                "show-progress": "",
                variant: "success"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          [
            _c("label", [_vm._v("Value label with precision")]),
            _c("b-progress", {
              staticClass: "progress-bar-danger",
              attrs: {
                value: "55",
                max: "100",
                precision: "2",
                "show-value": "",
                variant: "danger"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          [
            _c("label", [_vm._v("Progress label with precision")]),
            _c("b-progress", {
              staticClass: "progress-bar-warning",
              attrs: {
                value: "65",
                max: "100",
                precision: "2",
                "show-progress": "",
                variant: "warning"
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }