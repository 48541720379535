var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Colored Progress" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeColored) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Use")]),
        _c("code", [_vm._v("variant")]),
        _c("span", [
          _vm._v("prop to add different colors to progressbar and add ")
        ]),
        _c("code", [_vm._v(".progress-bar-{color_name}")]),
        _vm._v(". ")
      ]),
      _c(
        "div",
        { staticClass: "demo-vertical-spacing" },
        [
          _c("b-progress", { attrs: { value: "25", max: "100" } }),
          _c("b-progress", {
            staticClass: "progress-bar-secondary",
            attrs: { value: "35", max: "100", variant: "secondary" }
          }),
          _c("b-progress", {
            staticClass: "progress-bar-success",
            attrs: { value: "45", max: "100", variant: "success" }
          }),
          _c("b-progress", {
            staticClass: "progress-bar-danger",
            attrs: { value: "55", max: "100", variant: "danger" }
          }),
          _c("b-progress", {
            staticClass: "progress-bar-warning",
            attrs: { value: "65", max: "100", variant: "warning" }
          }),
          _c("b-progress", {
            staticClass: "progress-bar-info",
            attrs: { value: "75", max: "100", variant: "info" }
          }),
          _c("b-progress", {
            staticClass: "progress-bar-dark",
            attrs: { value: "85", max: "100", variant: "dark" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }